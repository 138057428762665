import React from "react";
import { useTranslation } from "react-i18next";

const Availability = () => {
  const { t } = useTranslation();

  const apartments = [
    {
      id: "1A",
      capacity: "2+2",
      location: t("separated"),
      view: t("seaside"),
      prices: [130, 90],
      special: t("airConditioning"),
    },
    {
      id: "1B",
      capacity: "2+2",
      location: t("separated"),
      view: t("seaside"),
      prices: [130, 90],
      special: t("airConditioning"),
    },
    {
      id: 4,
      capacity: "2+2",
      location: t("firstFloor"),
      view: t("seaside"),
      prices: [130, 90],
      special: t("airConditioning"),
    },
    {
      id: 6,
      capacity: "2",
      location: t("firstFloor"),
      view: t("garden"),
      prices: [80, 60],
      special: t("airConditioning"),
    },
    {
      id: 7,
      capacity: "2+2",
      location: t("secondFloor"),
      view: t("seaside"),
      prices: [130, 90],
      special: t("airConditioning"),
    },
    {
      id: 8,
      capacity: "2+2",
      location: t("secondFloor"),
      view: t("seaside"),
      prices: [130, 90],
      special: t("airConditioning"),
    },
    {
      id: 9,
      capacity: "2+2",
      location: t("secondFloor"),
      view: t("garden"),
      prices: [110, 80],
      special: t("airConditioning"),
    },
    {
      id: 10,
      capacity: "2+1",
      location: t("secondFloor"),
      view: t("garden"),
      prices: [100, 80],
      special: t("airConditioning"),
    },
    {
      id: 11,
      capacity: "2+2",
      location: t("mansard"),
      view: t("seaTerrace"),
      prices: [130, 90],
      special: t("airConditioning"),
    },
    {
      id: 12,
      capacity: "2+2",
      location: t("mansard"),
      view: t("seaTerrace"),
      prices: [160, 110],
      special: t("airConditioning"),
    },
    {
      id: 13,
      capacity: "2",
      location: t("separated"),
      view: t("noTerrace"),
      prices: [70, 60],
      special: t("airConditioning"),
    },
  ];

  return (
    <div className="select-availability-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center---">
              <h1 className="section-title pt-20 text-center">
                {t("accomodationPrices")}
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__select-availability-table-wrap">
              <div className="ltn__select-availability-table d-none d-md-block">
                <ul className="ltn__select-availability-table-head">
                  <li>{t("apartment")}</li>
                  <li>{t("capacity")}</li>
                  <li>{t("location")}</li>
                  <li>{t("view")}</li>
                  <li>01.07 - 31.08.</li>
                  <li>01.09.-30.06.</li>
                </ul>

                {apartments.map((apartment, index) => (
                  <ul
                    key={index}
                    className="ltn__select-availability-table-row"
                  >
                    <li>{apartment.id}</li>
                    <li>{apartment.capacity}</li>
                    <li>{apartment.location}</li>
                    <li>{apartment.view}</li>
                    {apartment.prices.map((price, priceIndex) => (
                      <li key={priceIndex}>{price}</li>
                    ))}
                  </ul>
                ))}
              </div>

              {/* Mobile Responsive */}
              <div className="ltn__select-availability-table-responsive d-md-none">
                {apartments.map((apartment, index) => (
                  <ul
                    key={index}
                    className="ltn__select-availability-table-row-responsive-item"
                  >
                    <li>
                      <span>{t("apartment")}</span>{" "}
                      <span className="tower-name">{apartment.id}</span>
                    </li>
                    <li>
                      <span>{t("capacity")}</span>{" "}
                      <span>{apartment.capacity}</span>
                    </li>
                    <li>
                      <span>{t("location")}</span>{" "}
                      <span>{apartment.location}</span>
                    </li>
                    <li>
                      <span>{t("view")}</span> <span>{apartment.view}</span>
                    </li>
                    {apartment.prices.map((price, priceIndex) => (
                      <li key={priceIndex}>
                        <span>{price}</span>
                      </li>
                    ))}
                    <li>
                      <span>{t("special")}</span>{" "}
                      <span>{apartment.special}</span>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
            <ul>
              <li>{t("additionalPerson")}</li>
              <li>{t("lunch")}</li>
              <li>{t("residential")}</li>
              <li>{t("dog")}</li>
              <li>{t("cleaning")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
